import logoPath from 'static/img/logo.png'
import flexAirLogoPath from 'static/img/flexAirLogo.png'

const logoPaths = {
  flexair: flexAirLogoPath,
  pilot_pipeline: logoPath,
}

export const getLogoPath = () => {
  return logoPaths[process.env.REACT_APP_INSTANCE] || logoPaths.pilot_pipeline
}
