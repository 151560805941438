// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__asideMenuPageBlockerStyles___1r3Kw{width:100%;height:100%;position:fixed;z-index:1000;background:rgba(0,0,0,0.4);visibility:hidden;opacity:0;transition:visibility 0s ease 0.4s, opacity 0.4s ease 0s}.styles-module__asideMenuPageBlockerStyles___1r3Kw.visible{height:100%;visibility:visible;opacity:1;transition:visibility 0s ease 0s, opacity 0.4s ease 0s}.styles-module__asideMenuContainerStyles___3-ts-{padding-top:20px;grid-column:1 / 1;grid-row:2 / -1;overflow:hidden;position:relative;background-color:#f3f3f4;transition:0.4s;min-height:100%;overflow-y:auto}.styles-module__asideMenuContainerStyles___3-ts- .ant-menu{padding-left:20px}.styles-module__asideMenuContainerStyles___3-ts- .ant-menu .ant-menu-item{margin-bottom:4px;border-radius:22px 0 0 22px;height:44px;font-size:16px;line-height:44px;font-weight:300;transition:all 0;padding:0 32px 0 16px}.styles-module__asideMenuContainerStyles___3-ts- .ant-menu .ant-menu-item .anticon{font-size:24px;position:relative;top:3px}.styles-module__asideMenuContainerStyles___3-ts- .ant-menu .ant-menu-item.ant-menu-item-selected{background:#fff;color:#1F97FB;font-weight:500}@media (max-width: 950px){.styles-module__asideMenuContainerStyles___3-ts-{position:fixed;left:-325px;height:0;padding-top:0;z-index:2000}.styles-module__asideMenuContainerStyles___3-ts-.visible{left:0;height:100%}.styles-module__asideMenuContainerStyles___3-ts- .ant-menu{padding:20px}.styles-module__asideMenuContainerStyles___3-ts- .ant-menu .ant-menu-item{border-radius:27px}}\n", ""]);
// Exports
exports.locals = {
	"asideMenuPageBlockerStyles": "styles-module__asideMenuPageBlockerStyles___1r3Kw",
	"asideMenuContainerStyles": "styles-module__asideMenuContainerStyles___3-ts-"
};
module.exports = exports;
