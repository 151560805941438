import React, { useState } from 'react'
import { accountNavigationContainerStyles } from './styles.module.scss'
import { TileNavigator, AvatarPicker, Modal, Avatar } from 'components'
import * as paths from 'utils/path-helpers/app'
import { observer, inject } from 'mobx-react'
import { FileAddOutlined } from '@ant-design/icons'
import classNames from 'classnames'

const tiles = [
  {
    label: 'My Account',
    to: paths.accountSubscriptionPath(),
  },
  {
    label: 'My Information',
    to: paths.accountUserPath(),
  },
  {
    label: 'Change Password',
    to: paths.accountPasswordPath(),
  },
  {
    label: 'Contact & Information',
    to: paths.accountContactPath(),
  },
]

const AccountNavigationContainer = props => {
  const [modalVisible, setModalVisible] = useState(false)
  const [avatar, setAvatar] = useState(null)

  const onCropEnd = image => {
    setAvatar(image)
  }

  const onOk = () => {
    props.userDataStore.uploadCurrentUserAvatar(avatar)
    setModalVisible(false)
  }

  const onCancel = () => {
    setModalVisible(false)
  }

  const wrapperClass = classNames({
    'user-avatar-picker-wrapper': true,
    'loader-visible': props.userDataStore.isUserDataLoading,
  })

  return (
    <div className={accountNavigationContainerStyles}>
      <Modal
        title="Upload Profile Image"
        visible={modalVisible}
        onOk={onOk}
        onCancel={onCancel}
        destroyOnClose
      >
        <AvatarPicker onCropEnd={onCropEnd} />
      </Modal>
      <div className="user-avatar-panel-wrapper">
        {props.userDataStore.fullName}
        <div
          onClick={() => setModalVisible(true)}
          className={wrapperClass}
        >
          <Avatar
            size={150}
            src={props.userDataStore.user.avatar}
          >
            {props.userDataStore.initials}
          </Avatar>
          <div className="user-avatar-picker-shadow">
            <FileAddOutlined />
          </div>
        </div>
      </div>
      <TileNavigator tiles={tiles} />
    </div>
  )
}

export default inject('userDataStore')(observer (AccountNavigationContainer))
